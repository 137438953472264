<template>
  <v-app>
    <v-container class="my-0 mx-0" style="max-width: 100% !important">
      <v-card class="px-4 py-5">
        <v-card-text class="px-0 py-0 mt-10" v-if="filteredLessons != null">
          <table class="irzOverviewTable" v-if="allData">
            <thead>
              <tr style="font-size: 13px; background-color: #ececec !important">
                <th style="border: 0px solid #dddddd; background: white"></th>
                <th style="border: 0px solid #dddddd; background: white"></th>
                <th style="border: 0px solid #dddddd; background: white"></th>
                <th style="border: 0px solid #dddddd; background: white"></th>
                <th
                  :colspan="_getWeeeksOfSemester(1).length"
                  class="blue--text text-center text-uppercase"
                >
                  1-р улирал
                </th>
                <th
                  :colspan="_getWeeeksOfSemester(2).length"
                  class="blue--text text-center text-uppercase"
                >
                  2-р улирал
                </th>
              </tr>
              <tr style="font-size: 13px; background-color: #ececec !important">
                <th style="width: 1%">No.</th>
                <th
                  class="fixed-column"
                  style="width: 20%; background-color: #ececec !important"
                >
                  Xичээлийн нэр
                </th>
                <th style="width: 4%">Бүлэг</th>
                <th class="text-center" style="width: 4%">Xэлбэр</th>

                <th
                  class="text-center"
                  style="width: 1%"
                  v-for="(week, ssIndex) in _getWeeeksOfSemester(1)"
                  :key="'head' + week.weekNumber + ssIndex + '1'"
                >
                  {{ week.weekNumber }}
                </th>
                <th
                  class="text-center"
                  style="width: 1%"
                  v-for="(week, ssIndex) in _getWeeeksOfSemester(2)"
                  :key="'head' + week.weekNumber + ssIndex + '1'"
                >
                  {{ week.weekNumber }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, lessonIndex) in filteredLessons" :key="item.id">
                <td class="fixed-column text-center">{{ lessonIndex + 1 }}</td>
                <td class="fixed-column">{{ item.courseInfo.COURSE_NAME }}</td>
                <td class="text-center blue--text">
                  {{ _getLessonClassGroupsName(item) }}
                </td>
                <td class="text-center">
                  <small
                    v-if="
                      item.esisLessonType &&
                      item.esisLessonType.esisLessonTypeId == 1
                    "
                    >{{ item.esisLessonType.name }}</small
                  >
                  <small
                    v-else-if="
                      item.esisLessonType &&
                      item.esisLessonType.esisLessonTypeId == 2
                    "
                    >{{ item.esisLessonType.name }}</small
                  >
                  <small
                    v-else-if="
                      item.esisLessonType &&
                      item.esisLessonType.esisLessonTypeId == 3
                    "
                    class="red--text"
                    >{{ item.esisLessonType.name }}</small
                  >
                </td>

                <td
                  class="text-center"
                  v-for="(week, ssIndex) in _getWeeeksOfSemester(1)"
                  :key="week.weekNumber + ssIndex + '1'"
                  :style="week.isHoliday ? 'background:yellow' : ''"
                >
                  {{ _getIrzNumberOfWeekLessonX(week.weekNumber, item) }}
                </td>
                <td
                  class="text-center"
                  v-for="(week, ssIndex) in _getWeeeksOfSemester(2)"
                  :key="week.weekNumber + ssIndex + '2'"
                  :style="week.isHoliday ? 'background:yellow' : ''"
                >
                  {{ _getIrzNumberOfWeekLessonX(week.weekNumber, item) }}
                </td>
              </tr>
              <tr class="font-weight-bold" style="background: #caefff">
                <td class="text-center" colspan="3">
                  ДОЛОО XОНОГИЙН НИЙЛБЭР ЦАГУУД
                </td>
                <td></td>
                <td
                  class="text-center"
                  v-for="(week, ssIndex) in _getWeeeksOfSemester(1)"
                  :key="week.weekNumber + ssIndex + '1'"
                  :style="week.isHoliday ? 'background:yellow' : ''"
                >
                  {{ _getIrzNumberOfWeekX(week.weekNumber) }}
                </td>
                <td
                  @click="_clickWeek(week)"
                  class="text-center"
                  v-for="(week, ssIndex) in _getWeeeksOfSemester(2)"
                  :key="week.weekNumber + ssIndex + '2'"
                  :style="week.isHoliday ? 'background:yellow' : ''"
                >
                  {{ _getIrzNumberOfWeekX(week.weekNumber) }}
                </td>
              </tr>
              <tr>
                <td colspan="3"></td>
                <td
                  :colspan="_getWeeeksOfSemester(1).length"
                  class="text-center"
                >
                  {{ _getSumOfSemester(1) }}
                </td>
                <td
                  :colspan="_getWeeeksOfSemester(1).length"
                  class="text-center"
                >
                  {{ _getSumOfSemester(2) }}
                </td>
              </tr>
            </tbody>
          </table>

          <table class="irzOverviewTable mt-10" v-if="allData">
            <thead>
              <tr style="font-size: 13px; background-color: #ececec !important">
                <th style="border: 0px solid #dddddd; background: white"></th>
                <th style="border: 0px solid #dddddd; background: white"></th>
                <th style="border: 0px solid #dddddd; background: white"></th>
                <th style="border: 0px solid #dddddd; background: white"></th>
                <th
                  :colspan="_getWeeeksOfSemester(3).length"
                  class="blue--text text-center text-uppercase"
                >
                  3-р улирал
                </th>
                <th
                  :colspan="_getWeeeksOfSemester(4).length"
                  class="blue--text text-center text-uppercase"
                >
                  4-р улирал
                </th>
              </tr>
              <tr style="font-size: 13px; background-color: #ececec !important">
                <th style="width: 1%">No.</th>
                <th
                  class="fixed-column"
                  style="width: 20%; background-color: #ececec !important"
                >
                  Xичээлийн нэр
                </th>
                <th style="width: 4%">Бүлэг</th>
                <th class="text-center" style="width: 4%">Xэлбэр</th>

                <th
                  class="text-center"
                  style="width: 1%"
                  v-for="(week, ssIndex) in _getWeeeksOfSemester(3)"
                  :key="'head' + week.weekNumber + ssIndex + '1'"
                >
                  {{ week.weekNumber }}
                </th>
                <th
                  class="text-center"
                  style="width: 1%"
                  v-for="(week, ssIndex) in _getWeeeksOfSemester(4)"
                  :key="'head' + week.weekNumber + ssIndex + '1'"
                >
                  {{ week.weekNumber }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, lessonIndex) in filteredLessons" :key="item.id">
                <td class="fixed-column text-center">{{ lessonIndex + 1 }}</td>
                <td class="fixed-column">{{ item.courseInfo.COURSE_NAME }}</td>
                <td class="text-center blue--text">
                  {{ _getLessonClassGroupsName(item) }}
                </td>
                <td class="text-center">
                  <small
                    v-if="
                      item.esisLessonType &&
                      item.esisLessonType.esisLessonTypeId == 1
                    "
                    >{{ item.esisLessonType.name }}</small
                  >
                  <small
                    v-else-if="
                      item.esisLessonType &&
                      item.esisLessonType.esisLessonTypeId == 2
                    "
                    >{{ item.esisLessonType.name }}</small
                  >
                  <small
                    v-else-if="
                      item.esisLessonType &&
                      item.esisLessonType.esisLessonTypeId == 3
                    "
                    class="red--text"
                    >{{ item.esisLessonType.name }}</small
                  >
                </td>

                <td
                  class="text-center"
                  v-for="(week, ssIndex) in _getWeeeksOfSemester(3)"
                  :key="week.weekNumber + ssIndex + '1'"
                  :style="week.isHoliday ? 'background:yellow' : ''"
                >
                  {{ _getIrzNumberOfWeekLessonX(week.weekNumber, item) }}
                </td>
                <td
                  class="text-center"
                  v-for="(week, ssIndex) in _getWeeeksOfSemester(4)"
                  :key="week.weekNumber + ssIndex + '2'"
                  :style="week.isHoliday ? 'background:yellow' : ''"
                >
                  {{ _getIrzNumberOfWeekLessonX(week.weekNumber, item) }}
                </td>
              </tr>
              <tr class="font-weight-bold" style="background: #caefff">
                <td class="text-center" colspan="3">
                  ДОЛОО XОНОГИЙН НИЙЛБЭР ЦАГУУД
                </td>
                <td></td>
                <td
                  class="text-center"
                  v-for="(week, ssIndex) in _getWeeeksOfSemester(3)"
                  :key="week.weekNumber + ssIndex + '1'"
                  :style="week.isHoliday ? 'background:yellow' : ''"
                >
                  {{ _getIrzNumberOfWeekX(week.weekNumber) }}
                </td>
                <td
                  @click="_clickWeek(week)"
                  class="text-center"
                  v-for="(week, ssIndex) in _getWeeeksOfSemester(4)"
                  :key="week.weekNumber + ssIndex + '2'"
                  :style="week.isHoliday ? 'background:yellow' : ''"
                >
                  {{ _getIrzNumberOfWeekX(week.weekNumber) }}
                </td>
              </tr>
              <tr>
                <td colspan="3"></td>
                <td
                  :colspan="_getWeeeksOfSemester(1).length"
                  class="text-center"
                >
                  {{ _getSumOfSemester(3) }}
                </td>
                <td
                  :colspan="_getWeeeksOfSemester(1).length"
                  class="text-center"
                >
                  {{ _getSumOfSemester(4) }}
                </td>
              </tr>
            </tbody>
          </table>
          <v-card v-else>
            <v-progress-linear
              color="red"
              height="6"
              indeterminate
            ></v-progress-linear>
          </v-card>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);
const fb = require("@/firebaseConfig.js");

export default {
  name: "PageLessonAttendanceChecking",
  components: {},
  data: () => ({
    currentSchoolWeek: 0,
    allData: null,
    // calendarButez3: [
    //   {
    //     session: 1,
    //     months: [
    //       {
    //         name: 9,
    //         days: [
    //           {
    //             day: 1,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 1,
    //             year: 2023,
    //           },
    //           {
    //             day: 4,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 2,
    //             year: 2023,
    //           },
    //           {
    //             day: 5,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 2,
    //             year: 2023,
    //           },
    //           {
    //             day: 6,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 2,
    //             year: 2023,
    //           },
    //           {
    //             day: 7,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 2,
    //             year: 2023,
    //           },
    //           {
    //             day: 8,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 2,
    //             year: 2023,
    //           },

    //           {
    //             day: 11,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 3,
    //             year: 2023,
    //           },
    //           {
    //             day: 12,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 3,
    //             year: 2023,
    //           },
    //           {
    //             day: 13,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 3,
    //             year: 2023,
    //           },
    //           {
    //             day: 14,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 3,
    //             year: 2023,
    //           },
    //           {
    //             day: 15,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 3,
    //             year: 2023,
    //           },

    //           {
    //             day: 18,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 4,
    //             year: 2023,
    //           },
    //           {
    //             day: 19,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 4,
    //             year: 2023,
    //           },
    //           {
    //             day: 20,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 4,
    //             year: 2023,
    //           },
    //           {
    //             day: 21,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 4,
    //             year: 2023,
    //           },
    //           {
    //             day: 22,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 4,
    //             year: 2023,
    //           },

    //           {
    //             day: 25,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 5,
    //             year: 2023,
    //           },
    //           {
    //             day: 26,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 5,
    //             year: 2023,
    //           },
    //           {
    //             day: 27,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 5,
    //             year: 2023,
    //           },
    //           {
    //             day: 28,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 5,
    //             year: 2023,
    //           },
    //           {
    //             day: 29,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 5,
    //             year: 2023,
    //           },
    //         ],
    //       },
    //       {
    //         name: 10,
    //         days: [
    //           {
    //             day: 2,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 6,
    //             year: 2023,
    //           },
    //           {
    //             day: 3,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 6,
    //             year: 2023,
    //           },
    //           {
    //             day: 4,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 6,
    //             year: 2023,
    //           },
    //           {
    //             day: 5,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 6,
    //             year: 2023,
    //           },
    //           {
    //             day: 6,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 6,
    //             year: 2023,
    //           },

    //           {
    //             day: 9,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 7,
    //             year: 2023,
    //           },
    //           {
    //             day: 10,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 7,
    //             year: 2023,
    //           },
    //           {
    //             day: 11,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 7,
    //             year: 2023,
    //           },
    //           {
    //             day: 12,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 7,
    //             year: 2023,
    //           },
    //           {
    //             day: 13,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 7,
    //             year: 2023,
    //           },

    //           {
    //             day: 16,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 8,
    //             year: 2023,
    //           },
    //           {
    //             day: 17,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 8,
    //             year: 2023,
    //           },
    //           {
    //             day: 18,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 8,
    //             year: 2023,
    //           },
    //           {
    //             day: 19,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 8,
    //             year: 2023,
    //           },
    //           {
    //             day: 20,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 8,
    //             year: 2023,
    //           },

    //           {
    //             day: 23,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 9,
    //             year: 2023,
    //           },
    //           {
    //             day: 24,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 9,
    //             year: 2023,
    //           },
    //           {
    //             day: 25,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 9,
    //             year: 2023,
    //           },
    //           {
    //             day: 26,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 9,
    //             year: 2023,
    //           },
    //           {
    //             day: 27,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 9,
    //             year: 2023,
    //           },

    //           {
    //             day: 30,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 10,
    //             year: 2023,
    //           },
    //           {
    //             day: 31,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 10,
    //             year: 2023,
    //           },
    //         ],
    //       },
    //       {
    //         name: 11,
    //         days: [
    //           {
    //             day: 1,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 10,
    //             year: 2023,
    //           },
    //           {
    //             day: 2,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 10,
    //             year: 2023,
    //           },
    //           {
    //             day: 3,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 10,
    //             year: 2023,
    //           },
    //         ],
    //       },
    //     ],
    //     weeks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    //     weeks2: [
    //       { weekNumber: 1 },
    //       { weekNumber: 2 },
    //       { weekNumber: 3 },
    //       { weekNumber: 4 },
    //       { weekNumber: 5 },
    //       { weekNumber: 6 },
    //       { weekNumber: 7 },
    //       { weekNumber: 8 },
    //       { weekNumber: 9 },
    //       { weekNumber: 10 },
    //     ],
    //     duration: [
    //       { day: 1, month: 9, year: 2023 },
    //       { day: 3, month: 11, year: 2023 },
    //     ],
    //     year: 2023,
    //   },
    //   {
    //     session: 2,
    //     months: [
    //       {
    //         name: 11,
    //         days: [
    //           {
    //             day: 13,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 11,
    //             year: 2023,
    //           },
    //           {
    //             day: 14,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 11,
    //             isHoliday: true,
    //             year: 2023,
    //           },
    //           {
    //             day: 15,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 11,
    //             year: 2023,
    //           },
    //           {
    //             day: 16,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 11,
    //             year: 2023,
    //           },
    //           {
    //             day: 17,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 11,
    //             year: 2023,
    //           },

    //           {
    //             day: 20,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 12,
    //             year: 2023,
    //           },
    //           {
    //             day: 21,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 12,
    //             year: 2023,
    //           },
    //           {
    //             day: 22,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 12,
    //             year: 2023,
    //           },
    //           {
    //             day: 23,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 12,
    //             year: 2023,
    //           },
    //           {
    //             day: 24,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 12,
    //             year: 2023,
    //           },

    //           {
    //             day: 27,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 13,
    //             year: 2023,
    //           },
    //           {
    //             day: 28,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 13,
    //             year: 2023,
    //           },
    //           {
    //             day: 29,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 13,
    //             year: 2023,
    //           },
    //           {
    //             day: 30,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 13,
    //             year: 2023,
    //           },
    //         ],
    //       },
    //       {
    //         name: 12,
    //         days: [
    //           {
    //             day: 1,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 13,
    //             year: 2023,
    //           },
    //           {
    //             day: 4,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 14,
    //             year: 2023,
    //           },
    //           {
    //             day: 5,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 14,
    //             year: 2023,
    //           },
    //           {
    //             day: 6,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 14,
    //             year: 2023,
    //           },
    //           {
    //             day: 7,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 14,
    //             year: 2023,
    //           },
    //           {
    //             day: 8,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 14,
    //             year: 2023,
    //           },
    //           {
    //             day: 9,
    //             garag: 6,
    //             garagName: "Бямба",
    //             weekNumber: 14,
    //             isHoliday: true,
    //             year: 2023,
    //           },
    //           {
    //             day: 10,
    //             garag: 7,
    //             garagName: "Ням",
    //             weekNumber: 14,
    //             isHoliday: true,
    //             year: 2023,
    //           },

    //           {
    //             day: 11,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 15,
    //             year: 2023,
    //           },
    //           {
    //             day: 12,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 15,
    //             year: 2023,
    //           },
    //           {
    //             day: 13,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 15,
    //             year: 2023,
    //           },
    //           {
    //             day: 14,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 15,
    //             year: 2023,
    //           },
    //           {
    //             day: 15,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 15,
    //             year: 2023,
    //           },
    //           {
    //             day: 16,
    //             garag: 6,
    //             garagName: "Бямба",
    //             weekNumber: 15,
    //             isHoliday: true,
    //             year: 2023,
    //           },
    //           {
    //             day: 17,
    //             garag: 7,
    //             garagName: "Ням",
    //             weekNumber: 15,
    //             isHoliday: true,
    //             year: 2023,
    //           },

    //           {
    //             day: 18,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 16,
    //             year: 2023,
    //           },
    //           {
    //             day: 19,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 16,
    //             year: 2023,
    //           },
    //           {
    //             day: 20,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 16,
    //             year: 2023,
    //           },
    //           {
    //             day: 21,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 16,
    //             year: 2023,
    //           },
    //           {
    //             day: 22,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 16,
    //             year: 2023,
    //           },
    //           {
    //             day: 23,
    //             garag: 6,
    //             garagName: "Бямба",
    //             weekNumber: 16,
    //             isHoliday: true,
    //             year: 2023,
    //           },
    //           {
    //             day: 24,
    //             garag: 7,
    //             garagName: "Ням",
    //             weekNumber: 16,
    //             isHoliday: true,
    //             year: 2023,
    //           },

    //           {
    //             day: 25,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 17,
    //             year: 2023,
    //           },
    //           {
    //             day: 26,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 17,
    //             year: 2023,
    //           },
    //           {
    //             day: 27,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 17,
    //             year: 2023,
    //           },
    //           {
    //             day: 28,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 17,
    //             year: 2023,
    //           },

    //           {
    //             day: 29,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 17,
    //             isHoliday: true,
    //             year: 2023,
    //           },
    //           {
    //             day: 30,
    //             garag: 6,
    //             garagName: "Бямба",
    //             weekNumber: 17,
    //             isHoliday: true,
    //             year: 2023,
    //           },
    //           {
    //             day: 31,
    //             garag: 7,
    //             garagName: "Ням",
    //             weekNumber: 17,
    //             isHoliday: true,
    //             year: 2023,
    //           },
    //           // { day: 29, garag: 5, garagName: "Баасан" },
    //         ],
    //       },
    //     ],
    //     weeks: [11, 12, 13, 14, 15, 16, 17, 18],
    //     weeks2: [
    //       { weekNumber: 11 },
    //       { weekNumber: 12 },
    //       { weekNumber: 13 },
    //       { weekNumber: 14 },
    //       { weekNumber: 15 },
    //       { weekNumber: 16 },
    //       { weekNumber: 17 },
    //     ],
    //     duration: [
    //       { day: 13, month: 11, year: 2023 },
    //       { day: 28, month: 12, year: 2023 },
    //     ],
    //     year: 2023,
    //   },
    //   {
    //     session: 3,
    //     months: [
    //       {
    //         name: 1,
    //         days: [
    //           {
    //             day: 28,
    //             garag: 7,
    //             garagName: "Ням",
    //             weekNumber: 19,
    //             year: 2024,
    //             isHoliday: true,
    //           },
    //           {
    //             day: 29,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 19,
    //             year: 2024,
    //           },
    //           {
    //             day: 30,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 19,
    //             year: 2024,
    //           },
    //           {
    //             day: 31,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 19,
    //             year: 2024,
    //           },
    //         ],
    //       },
    //       {
    //         name: 2,
    //         days: [
    //           {
    //             day: 1,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 19,
    //             year: 2024,
    //           },
    //           {
    //             day: 2,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 19,
    //             year: 2024,
    //           },
    //           {
    //             day: 3,
    //             garag: 6,
    //             garagName: "Бямба",
    //             weekNumber: 19,
    //             isHoliday: true,
    //             year: 2024,
    //           },
    //           {
    //             day: 4,
    //             garag: 7,
    //             garagName: "Ням",
    //             weekNumber: 19,
    //             isHoliday: true,
    //             year: 2024,
    //           },
    //           //week 20
    //           {
    //             day: 5,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 20,
    //             year: 2024,
    //           },
    //           {
    //             day: 6,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 20,
    //             year: 2024,
    //           },
    //           {
    //             day: 7,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 20,
    //             year: 2024,
    //           },
    //           {
    //             day: 8,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 20,
    //             year: 2024,
    //           },
    //           {
    //             day: 9,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 20,
    //             year: 2024,
    //           },
    //           {
    //             day: 10,
    //             garag: 6,
    //             garagName: "Бямба",
    //             weekNumber: 20,
    //             isHoliday: true,
    //             year: 2024,
    //           },
    //           {
    //             day: 11,
    //             garag: 7,
    //             garagName: "Ням",
    //             weekNumber: 20,
    //             isHoliday: true,
    //             year: 2024,
    //           },
    //           //week 21
    //           {
    //             day: 12,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 21,
    //             year: 2024,
    //           },
    //           {
    //             day: 13,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 21,
    //             year: 2024,
    //           },
    //           {
    //             day: 14,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 21,
    //             year: 2024,
    //           },
    //           {
    //             day: 15,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 21,
    //             year: 2024,
    //           },
    //           {
    //             day: 16,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 21,
    //             year: 2024,
    //           },
    //           {
    //             day: 17,
    //             garag: 6,
    //             garagName: "Бямба",
    //             weekNumber: 21,
    //             isHoliday: true,
    //             year: 2024,
    //           },
    //           {
    //             day: 18,
    //             garag: 7,
    //             garagName: "Ням",
    //             weekNumber: 21,
    //             isHoliday: true,
    //             year: 2024,
    //           },
    //           // weeek 22
    //           {
    //             day: 19,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 22,
    //             year: 2024,
    //           },
    //           {
    //             day: 20,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 22,
    //             year: 2024,
    //           },
    //           {
    //             day: 21,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 22,
    //             year: 2024,
    //           },
    //           {
    //             day: 22,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 22,
    //             year: 2024,
    //           },
    //           {
    //             day: 23,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 22,
    //             year: 2024,
    //           },
    //           {
    //             day: 24,
    //             garag: 6,
    //             garagName: "Бямба",
    //             weekNumber: 22,
    //             isHoliday: true,
    //             year: 2024,
    //           },
    //           {
    //             day: 25,
    //             garag: 7,
    //             garagName: "Ням",
    //             weekNumber: 22,
    //             isHoliday: true,
    //             year: 2024,
    //           },
    //           // weeek 23
    //           {
    //             day: 26,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 23,
    //             year: 2024,
    //           },
    //           {
    //             day: 27,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 23,
    //             year: 2024,
    //           },
    //           {
    //             day: 28,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 23,
    //             year: 2024,
    //           },
    //           {
    //             day: 29,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 23,
    //             year: 2024,
    //           },
    //         ],
    //       },
    //       {
    //         name: 3,
    //         days: [
    //           {
    //             day: 1,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 23,
    //             year: 2024,
    //           },
    //           {
    //             day: 2,
    //             garag: 6,
    //             garagName: "Бямба",
    //             weekNumber: 23,
    //             isHoliday: true,
    //             year: 2024,
    //           },
    //           {
    //             day: 3,
    //             garag: 7,
    //             garagName: "Ням",
    //             weekNumber: 23,
    //             isHoliday: true,
    //             year: 2024,
    //           },
    //           //week 24
    //           {
    //             day: 4,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 24,
    //             year: 2024,
    //           },
    //           {
    //             day: 5,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 24,
    //             year: 2024,
    //           },
    //           {
    //             day: 6,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 24,
    //             year: 2024,
    //           },
    //           {
    //             day: 7,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 24,
    //             year: 2024,
    //           },
    //           {
    //             day: 8,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 24,
    //             year: 2024,
    //           },
    //           {
    //             day: 9,
    //             garag: 6,
    //             garagName: "Бямба",
    //             weekNumber: 24,
    //             isHoliday: true,
    //             year: 2024,
    //           },
    //           {
    //             day: 10,
    //             garag: 7,
    //             garagName: "Ням",
    //             weekNumber: 24,
    //             isHoliday: true,
    //             year: 2024,
    //           },
    //           //weeek 25
    //           {
    //             day: 11,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 25,
    //             year: 2024,
    //           },
    //           {
    //             day: 12,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 25,
    //             year: 2024,
    //           },
    //           {
    //             day: 13,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 25,
    //             year: 2024,
    //           },
    //           {
    //             day: 14,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 25,
    //             year: 2024,
    //           },
    //           {
    //             day: 15,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 25,
    //             year: 2024,
    //           },
    //           {
    //             day: 16,
    //             garag: 6,
    //             garagName: "Бямба",
    //             weekNumber: 25,
    //             isHoliday: true,
    //             year: 2024,
    //           },
    //           {
    //             day: 17,
    //             garag: 7,
    //             garagName: "Ням",
    //             weekNumber: 25,
    //             isHoliday: true,
    //             year: 2024,
    //           },
    //           //week 26
    //           {
    //             day: 18,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 26,
    //             year: 2024,
    //           },
    //           {
    //             day: 19,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 26,
    //             year: 2024,
    //           },
    //           {
    //             day: 20,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 26,
    //             year: 2024,
    //           },
    //           {
    //             day: 21,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 26,
    //             year: 2024,
    //           },
    //           {
    //             day: 22,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 26,
    //             year: 2024,
    //           },
    //           {
    //             day: 23,
    //             garag: 6,
    //             garagName: "Бямба",
    //             weekNumber: 26,
    //             isHoliday: true,
    //             year: 2024,
    //           },
    //           {
    //             day: 24,
    //             garag: 7,
    //             garagName: "Ням",
    //             weekNumber: 26,
    //             isHoliday: true,
    //             year: 2024,
    //           },
    //           //week 27
    //           {
    //             day: 25,
    //             garag: 1,
    //             garagName: "Даваа",
    //             weekNumber: 27,
    //             year: 2024,
    //           },
    //           {
    //             day: 26,
    //             garag: 2,
    //             garagName: "Мягмар",
    //             weekNumber: 27,
    //             year: 2024,
    //           },
    //           {
    //             day: 27,
    //             garag: 3,
    //             garagName: "Лxагва",
    //             weekNumber: 27,
    //             year: 2024,
    //           },
    //           {
    //             day: 28,
    //             garag: 4,
    //             garagName: "Пүрэв",
    //             weekNumber: 27,
    //             year: 2024,
    //           },
    //           {
    //             day: 29,
    //             garag: 5,
    //             garagName: "Баасан",
    //             weekNumber: 27,
    //             year: 2024,
    //           },
    //           {
    //             day: 30,
    //             garag: 6,
    //             garagName: "Бямба",
    //             weekNumber: 27,
    //             isHoliday: true,
    //             year: 2024,
    //           },
    //           {
    //             day: 31,
    //             garag: 7,
    //             garagName: "Ням",
    //             weekNumber: 27,
    //             isHoliday: true,
    //             year: 2024,
    //           },
    //         ],
    //       },
    //     ],
    //     weeks: [19, 20, 21, 22, 23, 24, 25, 26, 27],
    //     weeks2: [
    //       { weekNumber: 19 },
    //       { weekNumber: 20 },
    //       { weekNumber: 21 },
    //       { weekNumber: 22 },
    //       { weekNumber: 23 },
    //       { weekNumber: 24 },
    //       { weekNumber: 25 },
    //       { weekNumber: 26 },
    //       { weekNumber: 27 },
    //     ],
    //     duration: [
    //       { day: 29, month: 1, year: 2024 },
    //       { day: 29, month: 3, year: 2024 },
    //     ],
    //     year: 2024,
    //   },
    // ],
    //
    currentSelectedSemester: null,

    loading: false,
    years: [2023, 2024],
    months: [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12],
    dayNames: [
      "Ням",
      "Даваа",
      "Мягмар",
      "Лхагва",
      "Пүрэв",
      "Баасан",
      "Бямба",
      "Ням",
    ],
    teacherAttendances: null,
    days: null,
    query: null,
    path: null,
    headerNames: null,
    bodyNames: null,
    lessons: null,
    selectedLesson: null,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    calendarButez() {
      return this.$store.state.calendarButez2;
    },
    filteredLessons() {
      var list = [];
      var counter = 0;
      if (this.lessons) {
        for (var ll of this.lessons) {
          if (
            ll.courseInfo &&
            ll.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE" &&
            ll.esisLessonType &&
            ll.esisLessonType.esisLessonTypeId > 2
          ) {
            var parentLL = null;
            const cc = list.find(
              (lesson) =>
                lesson.isCollected &&
                lesson.courseInfo.COURSE_ID &&
                lesson.courseInfo.COURSE_ID == ll.courseInfo.COURSE_ID
            );
            if (cc == undefined) {
              counter++;
              parentLL = ll;
              parentLL.index = counter;
              parentLL.uniqueId = ll.id + counter;
              parentLL.name3 = counter + ". " + ll.name2;
              parentLL.subLessons = [ll];
              parentLL.isCollected = true;
              // console.log(parentLL, parentLL.ref.path);
              list.push(parentLL);
            } else {
              cc.subLessons.push(ll);
            }
          } else {
            counter++;
            ll.index = counter;
            ll.uniqueId = ll.id + counter;
            ll.name3 = counter + ". " + ll.name2;
            list.push(ll);
          }
        }
      }
      return list;
    },
  },
  props: {
    teacherData: {
        type: Object,
        required: false,
    },
  },
  methods: {
    _clickWeek(week) {
      console.log(this.allData[week.weekNumber]);
      var xxx = this.allData[week.weekNumber];
      console.log(xxx);
      var counter = 0;
      for (const c of this.allData[week.weekNumber]) {
        counter++;
        // if(!c.isAllowed) console.log(c.year+"-"+c.month+"-"+c.day, c.selectedLesson.courseInfo.COURSE_NAME);
        console.log(counter, c.year + "-" + c.month + "-" + c.day, c.isAllowed);
      }
    },

    readAllExecutions() {
      var query = fb.db
        .collectionGroup("executions")
        .where("deleted", "==", false);
      if (this.teacherData.role == "teacher") {
        query = query.where("teacherRef", "==", this.teacherData.ref);
        // .where("semester", "==", this.currentSelectedSemester);
        // query = query.where("semester", "==", this.currentSelectedSemester); //555
      }

      this.loading = true;

      query.onSnapshot((docs) => {
        this.teacherAttendances = [];
        this.allData = [];
        console.log(docs.size);
        docs.forEach((doc) => {
          let att = doc.data();
          att.ref = doc.ref;
          att.id = doc.id;
          att.teachedAt = new Date(att.year + "-" + att.month + "-" + att.day);
          this._calWeekNumber(att);
          this._calDayName(att);

          // this.fixSemester(att);

          // if (att.semester == undefined) {
          //   this._setSemeter(att, true);
          // }

          this.isAllowed(att);
          // this.fixExecutionData(att);
          // this.fixExecutionYear(att);
          this.teacherAttendances.push(att); //99
          if (!this.allData[att.weekNumber]) {
            this.allData[att.weekNumber] = [att];
          } else {
            this.allData[att.weekNumber].push(att);
          }
        });
        this.loading = false;

        // this.$forceUpdate();
      });
    },
    _xxx() {
      this.readAllExecutions();
    },

    _getIrzNumberOfWeekLessonX(weekNumber, lesson) {
      var atts = this.allData[weekNumber];
      var xxx = [];
      if (atts) {
        if (lesson && lesson.isCollected) {
          xxx = atts.filter(
            (att) =>
              att.selectedLesson.courseInfo.COURSE_ID ==
              lesson.courseInfo.COURSE_ID
          );
        } else {
          xxx = atts.filter(
            (att) =>
              att.selectedLesson.courseInfo.COURSE_ID ==
                lesson.courseInfo.COURSE_ID &&
              att.selectedLesson.programId == lesson.programId
          );
        }
      }
      if (xxx && xxx.length > 0) return xxx.length;
      else return null;
    },
    _getSumOfSemester(semesterNumber) {
      var sum = 0;
      var weeks = this._getWeeeksOfSemester(semesterNumber);
      for (const week of weeks) {
        sum = sum + this._getIrzNumberOfWeekX(week.weekNumber);
      }
      return sum;
    },
    _getIrzNumberOfWeekX(weekNumber) {
      var xx = null;
      if (this.allData[weekNumber]) {
        xx = this.allData[weekNumber].filter((irz) => irz.isAllowed).length;
      }

      return xx;
    },
    _getWeeeksOfSemester(semester) {
      var sem = this.calendarButez.find((ss) => ss.session == semester);
      if (sem) return sem.weeks2;
      else return [];
    },

    _getWeekBeginEnd000(week) {
      var list = [];
      if (this.currentSelectedSemester) {
        var ss = this.calendarButez.find(
          (ss) => ss.session == this.currentSelectedSemester
        );
        for (const mm of ss.months) {
          for (const dd of mm.days) {
            if (dd.weekNumber == week) {
              dd.month = mm.name;
              list.push(dd);
            }
          }
        }
      }
      return list;
    },
    _getCurrentSchoolWeekNumber() {
      var date = new Date();
      // var year = date.getFullYear()
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var cc = null;
      if (this.calendarButez) {
        this.calendarButez.forEach((sem) => {
          if (sem.months) {
            sem.months.forEach((mm) => {
              if (mm.name == month) {
                if (mm.days) {
                  mm.days.forEach((dd) => {
                    if (dd.day == day) {
                      cc = dd.weekNumber;
                      return;
                    }
                  });
                }
              }
            });
          }
        });
      }
      return cc;
    },
    isAllowed(att) {
      // console.log(this.currentSelectedSemester)
      // if (att.semester == this.currentSelectedSemester) {
      if (att.selectedLesson && att.selectedLesson.byTeachers) {
        var xx = att.selectedLesson.byTeachers.find(
          (teacher) => teacher.teacherId == this.teacherData.id
        );
        if (xx) att.isAllowed = true;
        else {
          var yy = this.lessons.find(
            (lesson) =>
              lesson.courseInfo.COURSE_ID ==
              att.selectedLesson.courseInfo.COURSE_ID
          );
          if (yy) att.isAllowed = true;
          else {
            // console.log("12345678990",att.selectedLesson.courseInfo.COURSE_NAME)
            att.isAllowed = false;
            att.isAllowed2 = "1";
          }
        }
      } else {
        // console.log(att.ref.path, att.courseInfo.COURSE_NAME, att.selectedLesson)
        var zz = this.lessons.find(
          (lesson) =>
            lesson.courseInfo.COURSE_ID ==
            att.selectedLesson.courseInfo.COURSE_ID
        );
        //subLessons ued byTeachers bhgui
        if (zz) att.isAllowed = true;
        else {
          // console.log("12345678990",att.selectedLesson.courseInfo.COURSE_NAME)
          att.isAllowed = false;
          att.isAllowed2 = "2";
        }
      }
      // } else {
      // att.isAllowed = false;
      // att.isAllowed2 = "3";
      // }
    },
    _setSemeter(att, save) {
      this.calendarButez.forEach((semester) => {
        var month = semester.months.find((month) => month.name == att.month);
        if (month) {
          var day = month.days.find((day) => day.day == att.day);
          if (day) {
            if (save) att.ref.update({ semester: semester.session });
            else att.semester = semester.session;
            console.log("semester changed");
          }
        }
      });

      // if (
      //   (att.month >= 9 && att.month <= 10) ||
      //   (att.month == 11 && att.day <= 3)
      // ) {
      //   if (save) att.ref.update({ semester: 1 });
      //   else att.semester = 1;
      // } else if (
      //   att.month == 12 ||
      //   (att.month == 11 && att.day >= 13) ||
      //   (att.month == 1 && att.day <= 5)
      // ) {
      //   if (save) att.ref.update({ semester: 2 });
      //   else att.semester = 2;
      // }
    },
    _getLessonClassGroupsName(lesson) {
      var str = null;
      if (!lesson.isCollected) {
        for (const gg of lesson.classGroups) {
          str = str ? str + ", " + gg.classGroupName : gg.classGroupName;
        }
      } else if (lesson.subLessons) {
        // console.log(lesson.isCollected, lesson.name2, lesson);
        for (const xx of lesson.subLessons) {
          if (xx.classGroups && xx.classGroups[0]) {
            str = str
              ? str + ", " + xx.classGroups[0].classGroupFullName
              : xx.classGroups[0].classGroupFullName;
          }
        }
      }
      return str;
    },
    fetchNextPage() {
      var query = fb.db
        .collectionGroup("executions") //66
        .where("deleted", "==", false);
      if (this.teacherData.role == "teacher") {
        query = query.where("teacherRef", "==", this.teacherData.ref);
        // .where("semester", "==", this.currentSelectedSemester);
      }
      this.loading = true;
      query
        // .orderBy("teachedAt", "desc")
        // .orderBy("xeelj", "desc")
        // .limit(this.pageSize)
        .onSnapshot((docs) => {
          this.teacherAttendances = [];
          this.allData = [];
          // this.firstDoc = docs.docs[0];
          // this.lastDoc = docs.docs[docs.docs.length - 1];
          var executionData = [
            {
              burtgesenNumber: 0,
              temdeglelNumber: 0,
              homeworkNumber: 0,
              sedevNumber: 0,
            },
            {
              burtgesenNumber: 0,
              temdeglelNumber: 0,
              homeworkNumber: 0,
              sedevNumber: 0,
            },
            {
              burtgesenNumber: 0,
              temdeglelNumber: 0,
              homeworkNumber: 0,
              sedevNumber: 0,
            },
            {
              burtgesenNumber: 0,
              temdeglelNumber: 0,
              homeworkNumber: 0,
              sedevNumber: 0,
            },
          ];

          docs.forEach((doc) => {
            let att = doc.data();
            att.ref = doc.ref;
            att.id = doc.id;
            att.teachedAt = new Date(
              att.year + "-" + att.month + "-" + att.day
            );

            this._calWeekNumber(att);
            this._calDayName(att);

            // this.fixSemester(att);

            // if (att.semester == undefined) {
            //   // console.log("YESSSSSS");
            //   this._setSemeter(att, true);
            // }
            // if(att.semester==2) console.log(att)
            this.isAllowed(att);
            // this.fixExecutionData(att);
            // this.fixExecutionYear(att);

            if (att.isAllowed) {
              if (att.closed&&executionData[att.semester - 1]) {
                executionData[att.semester - 1].burtgesenNumber++;
              }
              if (att.selectedHomeWork&&executionData[att.semester - 1]) {
                executionData[att.semester - 1].homeworkNumber++;
              }
              if ((att.lessonConclusion || att.lessonNotes)&&executionData[att.semester - 1]) {
                executionData[att.semester - 1].temdeglelNumber++;
              }
              if (att.selectedParentTopic && executionData[att.semester - 1]) {
                executionData[att.semester - 1].sedevNumber++;
              }
            }
            this.teacherAttendances.push(att); //99
            if (!this.allData[att.weekNumber]) {
              this.allData[att.weekNumber] = [att];
            } else {
              this.allData[att.weekNumber].push(att);
            }
          });
          executionData.forEach((semesterData, sindex) => {
            this.teacherData.school.ref
              .collection(
                "teachersExecutionOverview-" + this.teacherData.school.currentYear
              )
              .doc(this.teacherData.id)
              .collection("semesters")
              .doc(String(sindex + 1))
              .set(semesterData, { merge: true });
          });
          this.loading = false;
          // this.$forceUpdate();
        });
    },
    _calWeekNumber(att) {
      var found = null;
      if (this.currentSelectedSemester) {
        var ss = this.calendarButez.find(
          (ss) => ss.session == this.currentSelectedSemester
        );
        for (const mm of ss.months) {
          if (mm.name == att.month) {
            for (const dd of mm.days) {
              if (dd.day == att.day) {
                att.weekNumber = dd.weekNumber;
                found = dd.weekNumber;
                break;
              }
            }
          }
        }
        return found;
      } else {
        for (const ss of this.calendarButez) {
          for (const mm of ss.months) {
            if (mm.name == att.month) {
              for (const dd of mm.days) {
                if (dd.day == att.day) {
                  att.weekNumber = dd.weekNumber;
                  found = dd.weekNumber;
                  break;
                }
              }
            }
          }
        }
      }
    },
    _calDayName(att) {
      var tmpDate = new Date(
        att.year +
          "-" +
          (att.month < 10 ? "0" + att.month : att.month) +
          "-" +
          (att.day < 10 ? "0" + att.day : att.day)
      );
      var dayName = tmpDate.toUTCString().substring(0, 3);

      if (dayName == "Mon") {
        att.dayName = this.dayNames[1];
      } else if (dayName == "Tue") {
        att.dayName = this.dayNames[2];
      } else if (dayName == "Wed") {
        att.dayName = this.dayNames[3];
      } else if (dayName == "Thu") {
        att.dayName = this.dayNames[4];
      } else if (dayName == "Fri") {
        att.dayName = this.dayNames[5];
      } else if (dayName == "Sat") {
        att.dayName = this.dayNames[6];
      } else if (dayName == "Sun") {
        att.dayName = this.dayNames[0];
      }
    },
    isAllowedLesson(lesson) {
      if (lesson && lesson.classGroupRefs) {
        for (var ll of lesson.classGroupRefs) {
          if (ll.path.includes("/departments/")) {
            lesson.isNotAllowed = true;
            break;
          }
        }
      }
    },
    async _setupp() {
      if (this.teacherData.school) {
        var query = this.teacherData.school.ref
          .collection("lessons-" + this.teacherData.school.currentYear)
          .where("deleted", "==", false);

        if (this.teacherData.role == "teacher") {
          query = query.where(
            "teacherRefs",
            "array-contains",
            this.teacherData.ref
          );
        }
        query = query.orderBy("courseInfo.COURSE_NAME", "desc");

        query.onSnapshot((docs) => {
          this.lessons = [];
          var counter = 0;
          docs.forEach((doc) => {
            counter++;
            let lesson = doc.data();
            lesson.id = doc.id;
            lesson.ref = doc.ref;
            lesson.topics = null;
            lesson.name2 = lesson.courseInfo.COURSE_NAME;
            lesson.name3 = counter + ". " + lesson.courseInfo.COURSE_NAME;
            lesson.classGroups
              ? lesson.classGroups.forEach((a) => {
                  lesson.name3 =
                    lesson.name3 +
                    " - " +
                    (a.programData.STUDENT_GROUP_NAME
                      ? a.programData.STUDENT_GROUP_NAME
                      : a.programData.fullName
                    ).toUpperCase();
                })
              : "";
            this.isAllowedLesson(lesson);
            if (!lesson.isNotAllowed) this.lessons.push(lesson);
          });
          this.fetchNextPage();
        });
      }
    },
  },
  created() {
    this.currentSchoolWeek = this._getCurrentSchoolWeekNumber();
    this._setupp();
    this.headerNames = [
      {
        text: "No.",
        sortable: false,
        width: "1%",
        value: "index",
      },
      {
        text: "Xичээлийн нэр",
        value: "courseInfo.COURSE_NAME",
        sortable: true,
      },
      {
        text: "Төрөл",
        sortable: true,
        value: "esisLessonType",
      },
      {
        text: "Бүлэг",
        value: "classGroups",
        sortable: true,
      },
      {
        text: "Суралцагч",
        value: "numberOfStudents",
        sortable: true,
        width: "2%",
      },
      // {
      //   text: "Дундаж дүн",
      //   align: "start",
      //   value: "scoreAverage",
      //   sortable: true,
      //   width: "2%",
      // },
      // {
      //   text: "Гүйцэтгэл",
      //   align: "start",
      //   value: "approvedPercentage",
      //   sortable: true,
      //   width: "2%",
      // },

      {
        text: "Үйлдэл",
        value: "actions",
      },
    ];
  },
};
</script>
<style>
[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: yellow;
  color: black;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}

.irzOverviewTable {
  border-collapse: collapse;
}

.irzOverviewTable td {
  border: 1px solid #dddddd;
  padding: 1px 4px;
  text-align: left;
  border: 1px solid #ddd;
  height: 1px !important;
}

.irzOverviewTable th {
  border: 1px solid #dddddd;
  height: 5px !important;
  padding: 1px 4px;
}

.fixed-column {
  position: sticky;
  left: 10px;
  z-index: 1;
  /* background-color: #ececec !important */
  /* Change this to your desired background color */
}
</style>
